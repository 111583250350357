<template>
  <div>
    <h4>Channel Subscriptions</h4>
    <hr class="">
    <b-tabs content-class="pt-1">
      <b-tab
        title="Active"
        active
      >
        <b-row>
          <b-col
            v-for="item in filterActive()"
            :id="item.id"
            :key="item.cf_channel_id"
            md="6"
            lg="3"
          >
            <b-card lazy>
              <b-card-text><b-avatar
                             size="50"
                             :src="`https://content.brimecdn.com/cdn-cgi/image/width=100,quality=100/brime/user/${item.cf_channel}/avatar`"
                             variant="light-primary"
                             class="badge-minimal mr-1"
                           /><span style="font-size: 18px; color: white; font-weight: bold; padding-right: 5px;">{{ item.cf_channel }} </span>
                <br><br>
                <strong>Subscriber Since:</strong> {{ getDate(item.started_at) }}<br>
                <strong>Next Bill Date:</strong> {{ (item.next_billing_at && !pausedSubs.includes(item.id)) ? getDate(item.next_billing_at) : 'Cancelled' }}
              </b-card-text>
              <b-button
                v-if="item.next_billing_at && !pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="cancelSubscription(item.id)"
              >
                Cancel Sub
              </b-button>
              <b-button
                v-if="!item.next_billing_at || pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="resumeSubscription(item.id)"
              >
                Resume Sub
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                class="ml-1"
                @click="$router.push(`/${item.cf_channel}`)"
              >
                View Channel
              </b-button>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-for="item in filterActiveLegacy()"
            :id="item.id"
            :key="item.cf_channel_id"
            md="6"
            lg="3"
          >
            <b-card lazy>
              <b-card-text><b-avatar
                             size="50"
                             :src="`https://content.brimecdn.com/cdn-cgi/image/width=100,quality=100/brime/user/${item.cf_channel}/avatar`"
                             variant="light-primary"
                             class="badge-minimal mr-1"
                           /><span style="font-size: 18px; color: white; font-weight: bold; padding-right: 5px;">{{ item.cf_channel }} </span>
                <br><br>
                <strong>Subscriber Since:</strong> {{ getDate(item.started_at) }}<br>
                <strong>Next Bill Date:</strong> {{ (item.next_billing_at && !pausedSubs.includes(item.id)) ? getDate(item.next_billing_at) : 'Cancelled' }}
              </b-card-text>
              <b-button
                v-if="item.next_billing_at && !pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="cancelSubscription(item.id)"
              >
                Cancel Sub
              </b-button>
              <b-button
                v-if="!item.next_billing_at || pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="resumeSubscription(item.id)"
              >
                Resume Sub
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                class="ml-1"
                @click="$router.push(`/${item.cf_channel}`)"
              >
                View Channel
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab
        title="Cancelled"
        lazy
      >
        <b-row>
          <b-col
            v-for="item in filterCancelled()"
            :id="item.id"
            :key="item.cf_channel_id"
            md="6"
            lg="3"
          >
            <b-card>
              <b-card-text><b-avatar
                             size="50"
                             :src="`https://content.brimecdn.com/cdn-cgi/image/width=100,quality=100/brime/user/${item.channelOwners[0]}/avatar`"
                             variant="light-primary"
                             class="badge-minimal mr-1"
                           /><span style="font-size: 18px; color: white; font-weight: bold; padding-right: 5px;">{{ item.cf_channel }} </span>
                <br><br>
                <strong>Subscriber Since:</strong> {{ getDate(item.started_at) }}<br>
                <strong>Next Bill Date:</strong> {{ (item.next_billing_at && !pausedSubs.includes(item.id)) ? getDate(item.next_billing_at) : 'Cancelled' }}
              </b-card-text>
              <b-button
                v-if="item.next_billing_at && !pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="cancelSubscription(item.id)"
              >
                Cancel Sub
              </b-button>
              <!--<b-button
                v-if="!item.next_billing_at || pausedSubs.includes(item.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="primary"
                @click="resumeSubscription(item.id)"
              >
                Resume Sub
              </b-button> -->
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                variant="outline-primary"
                class=""
                @click="$router.push(`/${item.cf_channel}`)"
              >
                View Channel
              </b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCol, BCard, BCardText, BRow, BButton, BTabs, BTab, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'

export default {
  components: {
    BCol,
    BCard,
    BCardText,
    BRow,
    BButton,
    BTabs,
    BTab,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      options: {},
      legacy: {},
      offlineImageUrl: '',
      pausedSubs: [],
      channelData: [],
    }
  },
  mounted() {
  },
  async created() {
    // this.$http.get('').then(res => { this.options = res.data.streams })
    const options = await this.getSubs()
    this.options = options.subs
    this.legacy = options.legacy_subs
  },
  methods: {
    async channelIcon(channelLookup) {
      window.brime.getChannel(channelLookup)
        .then(dataReturn => {
          this.channelData.push(dataReturn)
        })
    },
    filterActive() {
      let result = Object.values(this.options).filter(item => item.next_billing_at) 
      return result
    },
    filterActiveLegacy() {
      return Object.values(this.legacy).filter(item => item.next_billing_at)
    },
    filterCancelled() {
      return Object.values(this.options).filter(item => !item.next_billing_at)
    },
    cancelSubscription(subscriptionID) {
      axios.delete(`${window.brime.apiBase}/internal/billing/subscription/pause/${subscriptionID}?client_id=${window.brime.clientId}`)
        .then(() => {
          // handle success
          this.pausedSubs.push(subscriptionID)
        })
        .catch(() => {
          // handle error
        })
    },
    resumeSubscription(subscriptionID) {
      axios.post(`${window.brime.apiBase}/internal/billing/subscription/resume/${subscriptionID}?client_id=${window.brime.clientId}`)
        .then(() => {
          // handle success
          this.pausedSubs.splice(this.pausedSubs.indexOf(subscriptionID), 1)
        })
        .catch(() => {
          // handle error
        })
    },
    updateSubData() {
      setTimeout(async () => {
        this.options = await window.brime.getUserSubscriptions()
      }, 8000)
    },
    capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getDate(clipEpoch) {
      const d = new Date(clipEpoch * 1000)
      const year = d.getFullYear()
      const month = (d.getMonth() + 1).toString().padStart(2, '0') // "04"
      const day = d.getDate().toString().padStart(2, '0') // "09"
      return `${month}/${day}/${year}`
    },
    sendInfo(item) {
      this.selectedClip = item
    },
  },
}
</script>
<style>
.nav-tabs > .nav-item > .nav-link.active{
background-color: unset !important;
}
</style>
<style scoped>
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
