<template>
<div>
  <h1 class="mb-2">Payment Methods</h1>
  <b-button variant="primary" @click="updateMethod">Update Payment Method</b-button>
  </div>
</template>

<script>
import axios from 'axios'
import { BButton } from 'bootstrap-vue'
export default {
components: {
  BButton,
},
methods: {
    async updateMethod() {
      const token = await this.$auth.getTokenSilently()
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      window.cbInstance.openCheckout({
        hostedPage: () => {
          const messageBody = {
          }
          return axios
            .post(
              `https://api.brime.tv/v1/subs/payment_methods`,
              messageBody, config
            )
            .then(response => response.data.hosted_page)
        },
        success: (hostedPageId) => {
          this.isSubscribed = true
        },
      })
    },
},
}
</script>

<style>

</style>