<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-2 col-12"
    nav-class="nav-left"
  >
    <!-- subscriptions -->
    <b-tab
      active
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="CreditCardIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Channel Subscriptions</span>
      </template>

      <subs />
    </b-tab>
    <b-tab
      lazy
    >

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BoldIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Brime Pro</span>
      </template>

      <subs />
    </b-tab>
    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="SettingsIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Payment Methods</span>
      </template>
      <payment-methods />
    </b-tab>
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="ListIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Billing History</span>
      </template>
      <invoices />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import Subs from './Subs.vue'
import Invoices from './Invoices.vue'
import PaymentMethods from './PaymentMethods.vue'
// import AccountSettingNotification from './AccountSettingNotification.vue'
export default {
  components: {
    BTabs,
    BTab,
    Subs,
    Invoices,
    PaymentMethods,
  },
  data() {
    return {
      creatorStatus: '',
      userData: '',
      monetized: false,
      options: {},
    }
  },
  // eslint-disable-next-line no-empty-function
  async mounted() {
  },
  beforeCreate() {
  },
}
</script>
